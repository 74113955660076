/********** Root **********/
:root {
    /* Colors: */
    /* --unnamed-color-e94e1b: #E94E1B; */
    --unnamed-color-e94e1b: #AC9B73;
    --unnamed-color-3c3c3b: #3C3C3B;
    --unnamed-color-ac9b73: #AC9B73;
    --unnamed-color-e9e4da: #E9E4DA;
    --unnamed-color-ffffff: #FFFFFF;

    /* Font/text values */
    --unnamed-font-family-poppins: "Poppins", sans-serif;
    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-300: 300;
    --unnamed-font-weight-bold: 600;
    --unnamed-font-size-15: 14px;
    --unnamed-font-size-20: 18px;
    --unnamed-font-size-25: 25px;
    --unnamed-font-size-30: 30px;
    --unnamed-font-size-35: 33px;
    --unnamed-font-size-70: 70px;
    --unnamed-font-size-90: 90px;
    --unnamed-font-size-200: 200px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-line-spacing-30: 30px;
    --unnamed-line-spacing-38: 38px;
    --unnamed-line-spacing-45: 45px;
    --unnamed-line-spacing-53: 53px;
    --unnamed-line-spacing-106: 106px;
    --unnamed-line-spacing-139: 139px;
    --unnamed-line-spacing-297: 297px;
    --unnamed-text-transform-uppercase: uppercase;
  }

/* Character Styles */
.menue-_-poppins-–-25pt {
    font-family: var(--unnamed-font-family-poppins);
    font-weight: 200;
    font-size: clamp(20px, 2.32vh, 25px);
    line-height: clamp(20px, 2.32vh, 25px);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
}

.Header {
    width: 100%;
    height: clamp(100px, 9.26px, 125px);
    background: var(--unnamed-color-3c3c3b) 0% 0% no-repeat padding-box;
    opacity: 0.8;
    position: sticky;
    top: 0;
    z-index: 2;
    transition: opacity .25s ease-in-out 0s,visibility .25s ease-in-out 0s;
}

.BurgerMenu {
    display: none;
}   

.LogoPosition {
    height: clamp(35px, 3.89vh, 42px);
    width: auto;
    position: absolute;
    bottom: 28%;
    left: min(7.29vw, 140px);
}

.Hero, .Hero1, .Hero2, .Hero3, .Hero4 {
    height: 100vh;
    width: 100%;
    min-height: 695px;
}

.Hero1, .Hero2, .Hero3, .Hero4 {
    transition: opacity .25s ease-in-out 0s,visibility .25s ease-in-out 0s;
    background-size: cover;
    background-position: center;
    display: inline-block;
}

.Hero {
    margin-top: clamp(-100px, -9.26px, -125px);
    object-fit: cover;
    position: relative;
    overflow: hidden;
}

.Hero1 {
    background: transparent url('./media/Bilder_Hero/hac_hero_landscape_projektmanagement_1920_1080.jpg') 0% 0% no-repeat padding-box;
}

.Hero2 {
    background: transparent url('./media/Bilder_Hero/hac_hero_landscape_projektentwicklung_1920_1080.jpg') 0% 0% no-repeat padding-box;
}

.Hero3 {
    background: transparent url('./media/Bilder_Hero/hac_hero_landscape_bauueberwachung_1920_1080.jpg') 0% 0% no-repeat padding-box;
}

.Hero4 {
    background: transparent url('./media/Bilder_Hero/hac_hero_landscape_controllingplus_1920_1080.jpg') 0% 0% no-repeat padding-box;
}

.BlockDiv {
    position: absolute;
    top: clamp(100px, 9.26px, 125px);
    left: min(28.85%, 554px);
}

.BigBlock {
    position: relative;
    width: clamp(256px, 36.85vh, 398px);
    height: clamp(490px, 70.46vh, 761px);
    background: var(--unnamed-color-e94e1b) 0% 0% no-repeat padding-box;
    box-shadow: 5px 10px 15px #00000029;
    border-radius: 0px 0px 80px 0px;
}

.FixedCaption {
    top: clamp(43px, 9.7%, 105px);
    left: clamp(40px, 5.74vh, 62px);
    position: absolute;
}

.Caption {
    left: 0px;
    top: 47%;
    white-space: nowrap;
    position: absolute;
    font-family: var(--unnamed-font-family-poppins);
    font-size: clamp(22px, 2.58vh, 30px);
    font-weight: var(--unnamed-font-weight-300);
    color: var(--unnamed-color-3c3c3b);
}

.BigBlockClickable {
    bottom: 0px;
    left: clamp(40px, 5.74vh, 62px);
    height: clamp(164px, 23.52vh, 254px);
    width: auto;
    position: absolute;
}

.CircleDiv {
    top: 2vh;
    width: clamp(256px, 36.85vh, 398px);
    position: relative;
    display: flex;
    justify-content: space-between;
}

.Pfeil {
    height: clamp(19px, 2.78vh, 28px);
    position: relative;
    top: 0px;
    left: -5vh;
}

.SmallWhite {
    letter-spacing: 0px;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font-family: var(--unnamed-font-family-poppins);
    font-weight: 100;
    font-size: clamp(22px, 2.78vh, 30px);
    line-height: var(--unnamed-line-spacing-45);
}

.PositionBaubetreuung {
    position: absolute;
    left: 0px;
    font-family: var(--unnamed-font-family-poppins);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: clamp(58px, 8.33vh, 90px);
    line-height: var(--unnamed-line-spacing-139);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-transform: var(--unnamed-text-transform-uppercase);
    min-height: 139px;
}

.PositionBau {
    top: clamp(24px, 3.52vh, 38px);
}

.PositionBe {
    top: clamp(77px, 11.2vh, 121px);
}

.PositionTreu {
    top: clamp(131px, 18.89vh, 204px);
}

.PositionUng {
    top: clamp(184px, 26.57vh, 287px);
}

.BigNumberHero {
    font-family: var(--unnamed-font-family-poppins);
    font-size: clamp(129px, 18.52vh, 200px);
    font-weight: 200;
    color: var(--unnamed-color-ffffff);
    line-height: clamp(191px,23.52vh,297px);
}

.BigNumber1_3 {
    left: 30.65%;
}

.BigNumber2_4 {
    left: 61%;
}

.Raute1_3 {
    left: 15.33%;
}

.Raute2_4 {
    left: 45.98%;
}

.Kontakt1 {
    font-family: var(--unnamed-font-family-poppins);
    font-size: var(--unnamed-font-size-20);
    font-weight: var(--unnamed-font-weight-bold);
    line-height: var(--unnamed-line-spacing-30);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-3c3c3b);
}

.Kontakt2 {
    font-size: var(--unnamed-font-size-20);
    line-height: var(--unnamed-line-spacing-30);
    font-family: var(--unnamed-font-family-poppins);
    font-weight: var(--unnamed-font-weight-300);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-3c3c3b);
}

.Kontakt3 {
    font-size: var(--unnamed-font-size-20);
    line-height: var(--unnamed-line-spacing-30);
    font-family: var(--unnamed-font-family-poppins);
    font-weight: var(--unnamed-font-weight-300);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-e94e1b);
}

.Kontakt3 a, .Kontakt3 a:visited {
    text-decoration: underline;
    color: var(--unnamed-color-e94e1b);
}

.KontaktIcon {
    top: 34.42%;
    left: 54.27%;
    max-width: 132px;
    width: 33.17%;
    max-height: 133px;
    height: 33.42%;
    position: absolute;
    background: transparent url('./media/Logos_Grafiken/hac_kontakt.svg') 0% 0% no-repeat padding-box;
}

.InnerKontaktHeader {
    font-family: var(--unnamed-font-family-poppins);
    font-weight: var(--unnamed-font-weight-300);
    font-size: clamp(47px, 3.5vw, 70px);
    line-height: min(5.5vw, 106px);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-3c3c3b);
}

/* Slideshow */

  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }

  /* Buttons */

  .slideshowDot {
    width: clamp(42px, 7.04vh, 76px);
    height: clamp(42px, 7.04vh, 76px);
    background: 0% 0% no-repeat padding-box;
    border-radius: 38px;
    display: inline-block;
    cursor: pointer;
    background-color: #FFFFFF;
  }

  .slideshowDot.active {
    background-color: #AC9B73;
  }

.impressum {
    position: relative;
    left: min(7.29vw, 140px);
    top: 144px;
    margin-bottom: 255px;
    width: clamp(600px, 43.28vw, 863px);
}

.impressum p {
    font-family: var(--unnamed-font-family-poppins);
    font-weight: var(--unnamed-font-weight-300);
    font-size: var(--unnamed-font-size-20);
    line-height: var(--unnamed-line-spacing-45);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-3c3c3b);
    margin: 0px;
    margin-bottom: 144px;
}

.impressum h1 {
    font-family: var(--unnamed-font-family-poppins);
    font-weight: var(--unnamed-font-weight-300);
    font-size: var(--unnamed-font-size-70);
    line-height: var(--unnamed-line-spacing-106);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-e94e1b);
    margin: 0px;
    margin-bottom: 144px;
}

.impressum h3 {
    font-family: var(--unnamed-font-family-poppins);
    font-weight: var(--unnamed-font-weight-300); 
    font-size: 50px;
    line-height: 76px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-3c3c3b);
    margin: 0px;
    margin-bottom: 44px;
}

.impressum h4 {
    font-family: var(--unnamed-font-family-poppins);
    font-weight: var(--unnamed-font-weight-300); 
    font-size: 30px;
    line-height: var(--unnamed-line-spacing-45);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-3c3c3b);
    margin: 0px;
}

.impressum span {
    font-family: var(--unnamed-font-family-poppins);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-20);
    line-height: var(--unnamed-line-spacing-45);
    letter-spacing: var(--unnamed-character-spacing-0);
}

.impressum a, .impressum a:visited {
    color: var(--unnamed-color-3c3c3b);
    text-decoration: none;
}

#Projektmanagement, #Projektentwicklung, #Baubetreuung, #Controlling, #Kontakt {
    scroll-margin: clamp(100px, 9.26px, 125px);
}

.PageLink, .PageLink:visited {
    text-decoration: none!important;
    color: var(--unnamed-color-ffffff);
}

.MenuBar a, .MenuBar a:visited {
    text-decoration: none!important;
    color: var(--unnamed-color-ffffff);
}

.fixed-position {
    position: fixed;
 }

 .CookieContainer {
    background: #FFFFFF;
    position: absolute;
    top: max(calc((100vh - clamp(350px, 45.43vh, 469px) - 125px)/2), 35.19vh);
    left: calc((100vw - 768px)/2);
    height: clamp(350px, 45.43vh, 469px);
    width: 768px;
    display: block;
    z-index: 999;
    color: #3C3C3B;
 }

 .CookieHeader {
    font-family: var(--unnamed-font-family-poppins);
    font-size: clamp(22px, 1.56vw, 30px);
    line-height: 30px;
    font-weight: var(--unnamed-font-weight-bold);
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: clamp(22px, 4.26vh, 46px);
    padding-top: clamp(25px, 4.81vh, 52px)
 }

 .CookieContent {
    font-family: var(--unnamed-font-family-poppins);
    font-size: clamp(17px, 1.04vw, 20px);
    line-height: 30px;
    font-weight: var(--unnamed-font-weight-300);
 }

 .ButtonWrapper {
    position: relative;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding: 27px 0px 32px 0px;
 }

 .ButtonStyle {
    height: clamp(40px, 4.35vh, 47px);
    width: 212px;
    background: var(--unnamed-color-3c3c3b) 0% 0% no-repeat padding-box;
    padding: 0px;
    margin: 0px;
    border: 0px;
    border-radius: 0px;
    box-shadow: none;
    color: var(--unnamed-color-ffffff);
    font-size: clamp(17px, 1.04vw, 20px);
    font-family: var(--unnamed-font-family-poppins);
 }

 .ButtonStyle:hover {
    background: #E94E1B 0% 0% no-repeat padding-box;
 }

 .Overlay {
    color: white;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
 }

 .InfoLink {
    color: #E94E1B;
 }

 /** Picture Carousel **/

.carousel {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    margin: 50px 10%;
    position: relative;
  }

  .carousel button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 1;
    overflow: hidden;
  }

  .carousel button img {
    width: auto;
    height: 50px;
  }
  
  .carousel button#first {
    left: -70px;
  }
  
  .carousel button#last {
    right: -25px;
  }
  
  .carousel-image {
    width: 200px;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    position: relative;
  }
  
  .modal-image {
    max-width: 90vw;
    max-height: 70vh;
  }

  #modalFirst {
    position: absolute;
    top: 50%;
    left: -52px;
    padding: 0;
    border: none;
    background-color: white;
    cursor: pointer;
    z-index: 1;
  }

  #modalLast {
    position: absolute;
    top: 50%;
    right: -52px;
    padding: 0;
    border: none;
    background-color: white;
    cursor: pointer;
    z-index: 1;
  }

  #modalClose {
    position: absolute;
    top: 27px;
    right: 0px;
    padding: 0;
    border: none;
    background-color: white;
    cursor: pointer;
    z-index: 1;
  }
  
  .modal-button:first-child {
    left: 100px;
  }
  
  .modal-button:last-child {
    right: -100px;
  }